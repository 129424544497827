html,
input,
textarea {
  font-size: 24px;
  font-family: Georgia;
}

html,
body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
  background-color: var(--secondary);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  margin: 0px;
  padding: 0px;
  /* color: var(--secondary); */
}

.spacer-1 {
  width: 100%;
  height: 250px;
}

.page {
  width: 100vw;
  min-height: 100vh;
}

.width {
  width: 100%;
}

.height {
  height: 100%;
}

.clickable {
  pointer-events: all;
  cursor: pointer;
}

.hidden {
  opacity: 0;
}

.link {
  text-decoration: none;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-cover {
  object-fit: cover;
}
.img-contain {
  object-fit: contain;
}
.img-fill {
  object-fit: fill;
}

.ov-hidden {
  overflow: hidden;
}
.ov-visible {
  overflow: visible;
}

.shadow {
  box-shadow: 4px 4px 4px 4px #00000025, 4px 4px 4px 4px #00000025;
}

.no-click {
  pointer-events: none;
}

.all-click {
  pointer-events: fill;
}
