.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.row {
  flex-direction: row;
}
.column {
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.center {
  align-items: center;
  justify-content: center;
}

.space {
  justify-content: space-between;
}

.even {
  justify-content: space-evenly;
}

.jc-start {
  justify-content: flex-start;
}

.jc-end {
  justify-content: flex-end;
}

.ai-start {
  align-items: flex-start;
}

.ai-end {
  align-items: flex-end;
}
