/* Margin */
.m-0 {
  margin: 0;
}
.m-5 {
  margin: 5px;
}
.m-10 {
  margin: 10px;
}
.m-15 {
  margin: 15px;
}
.m-20 {
  margin: 20px;
}
.m-25 {
  margin: 25px;
}
.m-30 {
  margin: 30px;
}
.m-35 {
  margin: 35px;
}
.m-40 {
  margin: 40px;
}
.m-45 {
  margin: 45px;
}
.m-50 {
  margin: 50px;
}
.m-100 {
  margin: 100px;
}
.m-150 {
  margin: 150px;
}
.m-200 {
  margin: 200px;
}

/* Margin Vertical */

.mv-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.mv-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.mv-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}
.mv-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.mv-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}
.mv-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.mv-35 {
  margin-top: 35px;
  margin-bottom: 35px;
}
.mv-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.mv-45 {
  margin-top: 45px;
  margin-bottom: 45px;
}
.mv-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}
.mv-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}
.mv-150 {
  margin-top: 150px;
  margin-bottom: 150px;
}
.mv-200 {
  margin-top: 200px;
  margin-bottom: 200px;
}

/* Margin Horizontal */

.mh-0 {
  margin-left: 0;
  margin-right: 0;
}
.mh-5 {
  margin-left: 5px;
  margin-right: 5px;
}
.mh-10 {
  margin-left: 10px;
  margin-right: 10px;
}
.mh-15 {
  margin-left: 15px;
  margin-right: 15px;
}
.mh-20 {
  margin-left: 20px;
  margin-right: 20px;
}
.mh-25 {
  margin-left: 25px;
  margin-right: 25px;
}
.mh-30 {
  margin-left: 30px;
  margin-right: 30px;
}
.mh-35 {
  margin-left: 35px;
  margin-right: 35px;
}
.mh-40 {
  margin-left: 40px;
  margin-right: 40px;
}
.mh-45 {
  margin-left: 45px;
  margin-right: 45px;
}
.mh-50 {
  margin-left: 50px;
  margin-right: 50px;
}
.mh-100 {
  margin-left: 100px;
  margin-right: 100px;
}
.mh-150 {
  margin-left: 150px;
  margin-right: 150px;
}
.mh-200 {
  margin-left: 200px;
  margin-right: 200px;
}

/* Margin Left */

.ml-0 {
  margin-left: 0;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-35 {
  margin-left: 35px;
}
.ml-40 {
  margin-left: 40px;
}
.ml-45 {
  margin-left: 45px;
}
.ml-50 {
  margin-left: 50px;
}
.ml-100 {
  margin-left: 100px;
}
.ml-150 {
  margin-left: 150px;
}
.ml-200 {
  margin-left: 200px;
}

/* Margin Right */

.mr-0 {
  margin-right: 0;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-25 {
  margin-right: 25px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-35 {
  margin-right: 35px;
}
.mr-40 {
  margin-right: 40px;
}
.mr-45 {
  margin-right: 45px;
}
.mr-50 {
  margin-right: 50px;
}
.mr-100 {
  margin-right: 100px;
}
.mr-150 {
  margin-right: 150px;
}
.mr-200 {
  margin-right: 200px;
}

/* Margin Bottom */

.mb-0 {
  margin-bottom: 0;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-100 {
  margin-bottom: 100px;
}
.mb-150 {
  margin-bottom: 150px;
}
.mb-200 {
  margin-bottom: 200px;
}

/* Margin Top */

.mt-0 {
  margin-top: 0;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-100 {
  margin-top: 100px;
}
.mt-150 {
  margin-top: 150px;
}
.mt-200 {
  margin-top: 200px;
}

/* Padding */
.p-0 {
  padding: 0;
}
.p-5 {
  padding: 5px;
}
.p-10 {
  padding: 10px;
}
.p-15 {
  padding: 15px;
}
.p-20 {
  padding: 20px;
}
.p-25 {
  padding: 25px;
}
.p-30 {
  padding: 30px;
}
.p-35 {
  padding: 35px;
}
.p-40 {
  padding: 40px;
}
.p-45 {
  padding: 45px;
}
.p-50 {
  padding: 50px;
}
.p-100 {
  padding: 100px;
}
.p-150 {
  padding: 150px;
}
.p-200 {
  padding: 200px;
}

/* Padding Vertical */
.pv-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.pv-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.pv-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.pv-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.pv-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}
.pv-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.pv-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}
.pv-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.pv-45 {
  padding-top: 45px;
  padding-bottom: 45px;
}
.pv-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.pv-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.pv-150 {
  padding-top: 150px;
  padding-bottom: 150px;
}
.pv-200 {
  padding-top: 200px;
  padding-bottom: 200px;
}

/* Padding Horizontal */
.ph-0 {
  padding-left: 0;
  padding-right: 0;
}
.ph-5 {
  padding-left: 5px;
  padding-right: 5px;
}
.ph-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.ph-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.ph-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.ph-25 {
  padding-left: 25px;
  padding-right: 25px;
}
.ph-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.ph-35 {
  padding-left: 35px;
  padding-right: 35px;
}
.ph-40 {
  padding-left: 40px;
  padding-right: 40px;
}
.ph-45 {
  padding-left: 45px;
  padding-right: 45px;
}
.ph-50 {
  padding-left: 50px;
  padding-right: 50px;
}
.ph-100 {
  padding-left: 100px;
  padding-right: 100px;
}
.ph-150 {
  padding-left: 150px;
  padding-right: 150px;
}
.ph-200 {
  padding-left: 200px;
  padding-right: 200px;
}

/* Padding Left */
.pl-0 {
  padding-left: 0;
}
.pl-5 {
  padding-left: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-25 {
  padding-left: 25px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-35 {
  padding-left: 35px;
}
.pl-40 {
  padding-left: 40px;
}
.pl-45 {
  padding-left: 45px;
}
.pl-50 {
  padding-left: 50px;
}
.pl-100 {
  padding-left: 100px;
}
.pl-150 {
  padding-left: 150px;
}
.pl-200 {
  padding-left: 200px;
}

/* Padding Right */
.pr-0 {
  padding-right: 0;
}
.pr-5 {
  padding-right: 5px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-25 {
  padding-right: 25px;
}
.pr-30 {
  padding-right: 30px;
}
.pr-35 {
  padding-right: 35px;
}
.pr-40 {
  padding-right: 40px;
}
.pr-45 {
  padding-right: 45px;
}
.pr-50 {
  padding-right: 50px;
}
.pr-100 {
  padding-right: 100px;
}
.pr-150 {
  padding-right: 150px;
}
.pr-200 {
  padding-right: 200px;
}

/* Padding Bottom */
.pb-0 {
  padding-bottom: 0;
}
.pb-5 {
  padding-bottom: 5px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-35 {
  padding-bottom: 35px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-45 {
  padding-bottom: 45px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pb-150 {
  padding-bottom: 150px;
}
.pb-200 {
  padding-bottom: 200px;
}

/* Padding Top */
.pt-0 {
  padding-top: 0;
}
.pt-5 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-35 {
  padding-top: 35px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-45 {
  padding-top: 45px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-100 {
  padding-top: 100px;
}
.pt-150 {
  padding-top: 150px;
}
.pt-200 {
  padding-top: 200px;
}
