.rad-8 {
  border-radius: 8px;
}
.rad-15 {
  border-radius: 15px;
}
.rad-20 {
  border-radius: 20px;
}
.rad-25 {
  border-radius: 25px;
}
.rad-30 {
  border-radius: 30px;
}
.rad-35 {
  border-radius: 35px;
}
.rad-40 {
  border-radius: 40px;
}
.rad-45 {
  border-radius: 45px;
}
.rad-50 {
  border-radius: 50px;
}
