.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.absolute-fill {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.top-left {
  top: 0;
  left: 0;
}
/* Z-index from -10 to 9 */
.z--10 {
  z-index: -10;
}
.z--9 {
  z-index: -9;
}
.z--8 {
  z-index: -8;
}
.z--7 {
  z-index: -7;
}
.z--6 {
  z-index: -6;
}
.z--5 {
  z-index: -5;
}
.z--4 {
  z-index: -4;
}
.z--3 {
  z-index: -3;
}
.z--2 {
  z-index: -2;
}
.z--1 {
  z-index: -1;
}
.z-0 {
  z-index: 0;
}
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.z-3 {
  z-index: 3;
}
.z-4 {
  z-index: 4;
}
.z-5 {
  z-index: 5;
}
.z-6 {
  z-index: 6;
}
.z-7 {
  z-index: 7;
}
.z-8 {
  z-index: 8;
}
.z-9 {
  z-index: 9;
}

/* Z-index from 10 to 50 */
.z-10 {
  z-index: 10;
}
.z-20 {
  z-index: 20;
}
.z-30 {
  z-index: 30;
}
.z-40 {
  z-index: 40;
}
.z-50 {
  z-index: 50;
}
